import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './components/Home';
import NotFound from './components/NotFound';
import About from './components/About'; // Import About component or any other page components
import Header from './components/Header';
import Footer from './components/Footer';
import './css/HomeMediaQuery.css'; // Ensure your global styles are imported
import Technologies from './components/Technologies';
import ContactUsMain from './components/ContactUsMain';
import BrandingStrategy from './components/Services/BrandingStrategy';
import BrandPositioning from './components/Services/BrandPositioning';
import LogoVisual from './components/Services/LogoVisual';
import PackageDesign from './components/Services/PackageDesign';
import Advertising from './components/Services/Advertising';
import Seo from './components/Services/Seo';
import SMM from './components/Services/SMM';
import Analytics from './components/Services/Analytics';
import LogoQuestions from './components/Services/LogoQuestions';
import PaidAdvertising from './components/Services/PaidAdvertising';
import EmailMarketing from './components/Services/EmailMarketing';
import UIUXDesign from './components/Services/UIUXDesign';
import MobileApp from './components/Services/MobileApp';
import Ecommerce from './components/Services/Ecommerce';
import ContentCreation from './components/Services/ContentCreation';
import Podcast from './components/Services/Podcast';
import DigitalMarketing from './components/Services/DigitalMarketing';
import WebAppDevelopment from './components/Services/WebAppDevelopment';
import { NavigationProvider } from './components/NavigationContext';
import GreenScreen from './components/Services/GreenScreen';
import VideoProduction from './components/Services/VideoProduction';
import Photoshoot from './components/Services/Photoshoot';
import Blog from './components/Blog';
import HealthCare from './components/HealthCare';

const App = () => {
  return (
    <Router>
      <NavigationProvider>
        <Header />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/technologies" element={<Technologies />} />
          <Route path="/ContactUs" element={<ContactUsMain />} />
          <Route path="/Blog" element={<Blog />} />
          {/* <Route path="/HealthCare" element={<HealthCare />} /> */}
          <Route path="*" component={NotFound} />


          {/* Services Routes */}

          <Route path="/Branding-and-marketing" element={<BrandingStrategy />} />
          <Route path="/Brand-Positioning" element={<BrandPositioning />} />
          <Route path="/Logo-designing" element={<LogoVisual />} />
          <Route path="/logo-question" element={<LogoQuestions />} />
          <Route path="/Brand-And-Packaging-Design" element={<PackageDesign />} />
          <Route path="/Marketing-And-Advertising" element={<Advertising />} />
          <Route path="/Digital-Marketing" element={<DigitalMarketing />} />
          <Route path="/Search-Engine-optmization-Marketing" element={<Seo />} />
          <Route path="/Social-Media-Marketing" element={<SMM />} />
          <Route path="/GoogleAnalytics-SearchConsole" element={<Analytics />} />
          <Route path="/PPC-Advertising" element={<PaidAdvertising />} />
          <Route path="/Email-Marketing" element={<EmailMarketing />} />
          <Route path="/Website-Development" element={<WebAppDevelopment />} />
          <Route path="/UI-Ux-Design-Development" element={<UIUXDesign />} />
          <Route path="/Mobile-Application-Development" element={<MobileApp />} />
          <Route path="/E-Commerce-Web-Development" element={<Ecommerce />} />
          <Route path="/Content-Creation-And-Marketing" element={<ContentCreation />} />
          <Route path="/Podcast-Editing-Hosting" element={<Podcast />} />
          <Route path="/Green-Screen-Studio-Rent" element={<GreenScreen />} />
          <Route path="/Video-Production" element={<VideoProduction />} />
          <Route path="/professional-photoshoot" element={<Photoshoot />} />
        </Routes>
        <Footer />
      </NavigationProvider>
    </Router>
  );
};

export default App;
